import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import DatePicker, { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';

import EmployeeList from '../../Global/EmployeeList.component';
import ScheduleEditModal from './EditModal.component';
import ScheduleCreateModal from './CreateModal.component';
import { EmployeeDTRLayout } from '../../Global/PrintComponents/EmployeeDTRLayout.component';
import { EmployeeOTLayout } from '../../Global/PrintComponents/EmployeeOTLayout.component';
import { AttendanceReport } from '../../Global/PrintComponents/AttendanceReport.component';
import Card from 'react-bootstrap/Card';

import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export default function Page(props) {

    let userIsMobile = window.innerWidth <= 768;
    const [employeeList, setEmployeeList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState(0);

    const [selectedDate, setSelectedDate] = useState(new DateObject());
    //const [selectedSchedule, setSelectedSchedule] = useState(1);

    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [employeeSchedule, setEmployeeSchedule] = useState([]);
    const [employeeRecords, setEmployeeRecords] = useState([]);
    const [dtrApproved, setDTRApproved] = useState(null);

    const [printData, setPrintData] = useState([]);
    const [printTarget, setPrintTarget] = useState(1);
    const [printRange, setPrintRange] = useState(1);
    const [printType, setPrintType] = useState("dtr");

    const [attendReportData, setAttendReportData] = useState([]);
    const [assignee, setAssignee] = useState([]);

    const [editSchedID, setEditSchedID] = useState(null);
    //const [editKeyID, setEditKeyID] = useState(null);

    const [showEdit, setShowEdit] = useState(false);
    const openEdit = () => setShowEdit(true);
    const closeEdit = () => setShowEdit(false);
    const closeFetchEdit = () => {
        fetchEmployeeSchedule(selectedEmployee);
        setShowEdit(false);
    }

    const [showCreate, setShowCreate] = useState(false);
    const openCreate = () => setShowCreate(true);
    const closeCreate = () => setShowCreate(false);
    const closeFetchCreate = () => {
        fetchEmployeeSchedule(selectedEmployee);
        setShowCreate(false);
    }

    const [approvedListR, setApprovedListR] = useState([]);
    const [approvedListNR, setApprovedListNR] = useState([]);
    const [showApproved, setShowApproved] = useState(false);
    const openApproved = () => setShowApproved(true);
    const closeApproved = () => setShowApproved(false);

    const [showPrint, setShowPrint] = useState(false);
    const openPrint = (type) => {
        setPrintType(type);
        if (printTarget == 1 && selectedEmployee.length > 0) {
            // let schedData = employeeList.find(emp => emp.id === selectedEmployee[0]);
            // schedData.schedules = employeeSchedule;
            // setPrintData([schedData]);
            fetchEmployeeDTR(printTarget);
        } else if (printTarget == 2 || printTarget == 3) {
            fetchEmployeeDTR(printTarget);
        }
        //setPrintData([employeeSchedule]);
        //setShowPrint(true);
    };

    const closePrint = () => setShowPrint(false);
    const printPDFRef = useRef();


    const [showAttendReport, setShowAttendReport] = useState(false);
    const openAttendReport = () => {
        fetchAttendReportData();
    };
    const closeAttendReport = () => setShowAttendReport(false);
    const attendReportRef = useRef();

    const timeList = {
        "12:00 AM": "0:00", "12:30 AM": "0:30",
        "1:00 AM": "1:00", "1:30 AM": "1:30",
        "2:00 AM": "2:00", "2:30 AM": "2:30",
        "3:00 AM": "3:00", "3:30 AM": "3:30",
        "4:00 AM": "4:00", "4:30 AM": "4:30",
        "5:00 AM": "5:00", "5:30 AM": "5:30",
        "6:00 AM": "6:00", "6:30 AM": "6:30",
        "7:00 AM": "7:00", "7:30 AM": "7:30",
        "8:00 AM": "8:00", "8:30 AM": "8:30",
        "9:00 AM": "9:00", "9:30 AM": "9:30",
        "10:00 AM": "10:00", "10:30 AM": "10:30",
        "11:00 AM": "11:00", "11:30 AM": "11:30",
        "12:00 PM": "12:00", "12:30 PM": "12:30",
        "1:00 PM": "13:00", "1:30 PM": "13:30",
        "2:00 PM": "14:00", "2:30 PM": "14:30",
        "3:00 PM": "15:00", "3:30 PM": "15:30",
        "4:00 PM": "16:00", "4:30 PM": "16:30",
        "5:00 PM": "17:00", "5:30 PM": "17:30",
        "6:00 PM": "18:00", "6:30 PM": "18:30",
        "7:00 PM": "19:00", "7:30 PM": "19:30",
        "8:00 PM": "20:00", "8:30 PM": "20:30",
        "9:00 PM": "21:00", "9:30 PM": "21:30",
        "10:00 PM": "22:00", "10:30 PM": "22:30",
        "11:00 PM": "23:00", "11:30 PM": "23:30",
    }

    useEffect(() => {
        fetchEmployeeSchedule(selectedEmployee);
    }, [selectedEmployee, selectedDate]);

    const setFetchEmployeeSchedule = (id) => {
        if (selectedEmployee !== id) {
            setSelectedEmployee(id);
        }
    }

    const fetchEmployeeDTR = async (type) => {
        await axios.post(`/api/office/dtr`, { employee: selectedEmployee[0], offcode: props.user.office.offcode, type: type, period: printRange, month: selectedDate.month.number, year: selectedDate.year, division: selectedDivision }).then(({ data }) => {
            setPrintData(data.employees);
            setShowPrint(true);
        })
    }

    const checkDTRApproved = async () => {
        Swal.fire({
            icon: 'info',
            text: 'Loading Schedule...',
            showConfirmButton: false
        });
        await axios.post(`/api/office/checkdtrapproved`, { month: selectedDate.month.number, year: selectedDate.year, division: selectedDivision }).then(({ data }) => {
            setApprovedListR(data.employeeCheck.permanent);
            setApprovedListNR(data.employeeCheck.nonpermanent);
            Swal.close();
            openApproved();
        })
    }

    const fetchAttendReportData = async () => {
        Swal.fire({
            icon: 'info',
            text: 'Fetching report...',
            showConfirmButton: false
        });
        await axios.post(`/api/office/dtr`, { offcode: props.user.office.offcode, month: selectedDate.month.number, year: selectedDate.year, division: selectedDivision }).then(({ data }) => {
            let tempData = [];
            //console.log('attend report', data);
            setAssignee(data.assignee);
            //console.log(data.employees.length);
            data.employees.forEach((emp) => {
                if (!(emp.ESTA in tempData)) {
                    tempData[emp.ESTA] = [];
                }
                if (emp.schedules.length !== 0) {
                    tempData[emp.ESTA].push(emp);
                    // tempData["wew"].push(emp);
                }
            });
            Swal.close();
            setAttendReportData(tempData);
            setShowAttendReport(true);
        })
    }

    const fetchEmployeeList = async () => {
        await axios.post(`/api/office/query/${props.user.office.offcode}`, { employee: true, leavecredits: false, cto: false }).then(({ data }) => {
            //console.log('employee list', data);
            setDivisionList(data.office.divisions);
            setEmployeeList(data.office.employees);
        })
    }

    const fetchEmployeeSchedule = async (id) => {
        if (id !== null && id.length !== 0) {
            Swal.fire({
                icon: 'info',
                text: 'Loading Schedule...',
                showConfirmButton: false
            });
            await axios.get(`/api/employeeschedule/${id}`, { params: { month: selectedDate.month.number, year: selectedDate.year } }).then(({ data }) => {
                if (data.result) {
                    setDTRApproved(data.approved);
                    setEmployeeSchedule(data.sched);
                    let recordsTemp = {};
                    data.records.forEach((rec) => {
                        let recTemp = new DateObject(rec.attendance);
                        let hours = recTemp.hour;
                        let minutes = recTemp.minute;
                        let ampm = hours >= 12 ? 'PM' : 'AM';
                        hours = hours % 12;
                        hours = hours ? hours : 12;
                        hours = (hours < 10 ? '0' : '') + hours;
                        minutes = minutes < 10 ? '0' + minutes : minutes;
                        let recStr = recTemp.month.number + "/" + recTemp.day + "/" + recTemp.year + " " + hours + ":" + minutes + " " + ampm;
                        recordsTemp[' ' + rec.id] = recStr;
                    })
                    Swal.close();
                    setEmployeeRecords(recordsTemp);
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const refreshDTR = async () => {
        //var start = new Date().getTime();
        if (employeeSchedule.length !== 0 && selectedEmployee !== null) {//&& employeeRecords !== 0){
            const formData = new FormData()
            formData.append('emp_id', selectedEmployee)
            formData.append('month', selectedDate.month.number)
            formData.append('year', selectedDate.year)
            Swal.fire({
                icon: 'info',
                text: 'Refreshing...',
                showConfirmButton: false
            });
            await axios.post(`/api/employeeschedule/refreshdtr`, formData).then(({ data }) => {
                // console.log('generate emp dtr', data);
                if (data.result) {
                    // Swal.update({
                    //     icon:"success",
                    //     text:data.message,
                    //     showConfirmButton:true
                    // });
                    Swal.close();
                    setEmployeeSchedule(data.sched);
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
                // var end = new Date().getTime();
                // var time = end - start;
                // alert('Execution time: ' + time);
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const generatePDFFile = async () => {
        if (employeeSchedule.length !== 0 && selectedEmployee !== null) {//&& employeeRecords !== 0){
            const formData = new FormData()
            formData.append('emp_id', selectedEmployee)
            formData.append('month', selectedDate.month.number)
            formData.append('year', selectedDate.year)
            Swal.fire({
                icon: 'info',
                text: 'Refreshing...',
                showConfirmButton: false
            });
            await axios.post(`/api/employeeschedule/generatePDFFile`, formData).then(({ data }) => {
                // console.log('generate emp dtr', data);
                if (data.result) {
                    Swal.update({
                        icon: "success",
                        text: data.message,
                        showConfirmButton: true
                    });
                    //console.log(data);
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                    //console.log(data);
                }
                // var end = new Date().getTime();
                // var time = end - start;
                // alert('Execution time: ' + time);
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const refreshOfficeDTR = async () => {
        const formData = new FormData()
        formData.append('office', props.user.office.offcode)
        formData.append('month', selectedDate.month.number)
        formData.append('year', selectedDate.year)
        formData.append('division', selectedDivision)
        Swal.fire({
            icon: 'info',
            text: 'Refreshing...',
            showConfirmButton: false
        })
        await axios.post(`/api/employeeschedule/refreshofficedtr`, formData).then(({ data }) => {
            //console.log('generate office dtr', data);
            if (data.result) {
                // Swal.fire({
                //     icon: "success",
                //     text: data.message,
                //     showConfirmButton: true
                // }).then(() => {
                Swal.close();
                fetchEmployeeSchedule(selectedEmployee);
                // });
            } else {
                Swal.update({
                    icon: "error",
                    text: data.message,
                    showConfirmButton: true
                });
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
        //}
    }

    const scheduleRow = (sched, i) => {
        const date = new DateObject(sched.date);
        let legends = ["VL", "FL", "SL", "OB", "SPL", "*SPL", "CTO", "HL", "WS", "A", "ML", "PL", "*SL", "VAWC", "SLBW", "RP", "CL", "AL", "CTO", "CQT", "CQTL"];
        const checkDT = (dt, ms = false, scDt = sched.date) => {
            if (dt === "" || dt === null) {
                return "";
            }
            if (legends.includes(dt)) {
                return dt;
            }
            let res = ms ? new DateObject(parseInt(dt)).format("hh:mm") : new DateObject(`${scDt} ${dt}`).format("hh:mm");
            //console.log(dt, ms, scDt, res);
            return res;
        }

        let schedAmIn = checkDT(sched.sched_am_in);
        let schedAmOut = checkDT(sched.sched_am_out);
        let schedPmIn = checkDT(sched.sched_pm_in);
        let schedPmOut = checkDT(sched.sched_pm_out);
        let attAmIn = checkDT(sched.attend_am_in, true);
        let attAmOut = checkDT(sched.attend_am_out, true);
        let attPmIn = checkDT(sched.attend_pm_in, true);
        let attPmOut = checkDT(sched.attend_pm_out, true);
        let otCutOff = checkDT(sched.ot_cutoff);
        let otIn = checkDT(sched.ot_in, true);
        let otOut = checkDT(sched.ot_out, true);

        let utTot = 0;
        if (sched.undertime) {
            let ut = sched.undertime.split(",").map(Number);
            utTot = ut[0] + ut[1] + ut[2] + ut[3];
        }

        let empApp = null;
        let offApp = null;

        if (!Array.isArray(dtrApproved)) {
            if (dtrApproved) {
                empApp = dtrApproved.emp_approved;
                offApp = dtrApproved.off_approved;
            }
        } else {
            if (i < 15) {
                if (dtrApproved[0]) {
                    empApp = dtrApproved[0].emp_approved;
                    offApp = dtrApproved[0].off_approved;
                }
            } else {
                if (dtrApproved[1]) {
                    empApp = dtrApproved[1].emp_approved;
                    offApp = dtrApproved[1].off_approved;
                }
            }
        }

        return (
            <Row key={i} className="border-bottom border-1 border-dark text-center">
                <Col className={`border-end border-2 border-dark text-end ${empApp || offApp ? "" : "editSchedButton"}`} xs="2" onClick={empApp || offApp ? undefined
                    : (e) => {
                        setEditSchedID(sched.id);
                        openEdit();
                    }}>
                    {empApp ? offApp ? <DoneAllIcon className="text-success mt-0 me-2" /> : <CheckIcon style={{ fontSize: 20 }} className="text-primary me-1" /> : null}
                    <Form.Label className={`p-0 m-0 ${empApp ? offApp ? "text-success" : "text-primary" : ""}`}>{`${date.weekDay.shortName}, ${date.month.name} ${date.day}`}</Form.Label>
                </Col>
                <Col className={`border-end border-2 border-dark ${sched.sched_approved === 1 ? "" : "text-danger"}`} xs="4">
                    <Row>
                        <Col className="border-end border-1 border-dark p-0">
                            &nbsp;{schedAmIn}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0 bg-light">
                            &nbsp;{schedAmOut}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0">
                            &nbsp;{schedPmIn}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0 bg-light">
                            &nbsp;{schedPmOut}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0">
                            &nbsp;{sched.ot_indicator}
                        </Col>
                        <Col className="p-0" xs="2">
                            &nbsp;{otCutOff}
                        </Col>
                    </Row>
                </Col>
                <Col className="border-end border-2 border-dark" xs="4">
                    <Row>
                        <Col className={"border-end border-1 p-0 border-dark" + (sched.am_in_entry === 0 ? "" : sched.am_in_entry === 1 ? " text-primary" : sched.am_in_entry === 2 ? " text-danger" : sched.am_in_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attAmIn}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark bg-light" + (sched.am_out_entry === 0 ? "" : sched.am_out_entry === 1 ? " text-primary" : sched.am_out_entry === 2 ? " text-danger" : sched.am_out_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attAmOut}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark" + (sched.pm_in_entry === 0 ? "" : sched.pm_in_entry === 1 ? " text-primary" : sched.pm_in_entry === 2 ? " text-danger" : sched.pm_in_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attPmIn}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark p-0 bg-light" + (sched.pm_out_entry === 0 ? "" : sched.pm_out_entry === 1 ? " text-primary" : sched.pm_out_entry === 2 ? " text-danger" : sched.pm_out_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attPmOut}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark" + (sched.ot_in_entry === 0 ? "" : sched.ot_in_entry === 1 ? " text-primary" : sched.ot_in_entry === 2 ? " text-danger" : " text-success-lighter")}>
                            &nbsp;{otIn}
                        </Col>
                        <Col className={"p-0 bg-light" + (sched.ot_out_entry === 0 ? "" : sched.ot_out_entry === 1 ? " text-primary" : sched.ot_out_entry === 2 ? " text-danger" : " text-success-lighter")}>
                            &nbsp;{otOut}
                        </Col>
                    </Row>
                </Col>
                <Col className="p-0 border-end border-2 border-dark" xs="1">
                    &nbsp;{utTot}
                </Col>
                <Col className="border-end border-2 border-dark" xs="1">
                    &nbsp;{sched.overtime}
                </Col>
            </Row>
        )
    }

    const renderScheds = () => {
        //let start = performance.now();
        let result = employeeSchedule.map((sched, i) => (
            scheduleRow(sched, i)
        ));
        //let end = performance.now();
        //let time = end - start;
        //console.log("Execution time: " + time + " milliseconds");
        return result;
    }

    useEffect(() => {
        fetchEmployeeList();
    }, []);

    const createNewSchedule = async () => {
        Swal.fire({
            icon: 'info',
            text: 'Creating Schedule...',
            showConfirmButton: false
        })

        let schedData = {
            mode: 0,
            office: props.user.office.offcode,
            month: selectedDate.month.number,
            year: selectedDate.year,
            division: selectedDivision
        }

        await axios.post(`/api/employeeschedule/create`, schedData).then(({ data }) => {
            //console.log('create schedule', data);
            Swal.fire({
                icon: "success",
                text: "Schedules Created",
                showConfirmButton: true
            }).then(() => {
                //fetchEmployeeSchedule(selectedEmployee);
            });
            //Swal.close();
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    const createAll = () => {
        let division = selectedDivision === "" ? "All Employees" : selectedDivision === '0' ? "Unassigned Employees" : divisionList.find(div => div.id == selectedDivision).name;
        Swal.fire({
            title: `Create Schedule for ${division}`,
            text: 'This will overwrite any existing schedule. Proceed?',
            //showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-2',
                confirmButton: 'order-1 right-gap',
                //denyButton: 'order-2',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                createNewSchedule();
            }
        })
    }

    // useEffect(() => {
    //     console.log(employeeSchedule);
    // }, [employeeSchedule])

    return (
        <div className="container-fluid page-container h-100 w-100 p-0">


            <Modal dialogClassName="sched-print-modal" show={showPrint} onHide={closePrint}>
                <Modal.Header closeButton>
                    <Modal.Title>Employee DTR</Modal.Title>
                    <ReactToPrint
                        trigger={() => <Button className="ms-3" variant="primary" >Print</Button>}
                        content={() => printPDFRef.current}
                    />
                </Modal.Header>
                <Modal.Body className="" ref={printPDFRef}>
                    {printData.map((dtr, index) => (
                        printType == "dtr" ?
                        <EmployeeDTRLayout
                            key={index}
                            selectedDate={selectedDate}
                            printData={dtr}
                            printRange={printRange}
                            closePrint={closePrint}
                            ref={printPDFRef}
                        /> :
                        <EmployeeOTLayout
                            key={index}
                            selectedDate={selectedDate}
                            printData={dtr}
                            printRange={printRange}
                            closePrint={closePrint}
                            ref={printPDFRef}
                        />
                    ))}
                </Modal.Body>
            </Modal>
            <Modal dialogClassName="attend-report-modal" show={showAttendReport} onHide={closeAttendReport}>
                <Modal.Header closeButton>
                    <Modal.Title>Attendance Report</Modal.Title>
                    <ReactToPrint
                        trigger={() => <Button className="ms-3" variant="primary" >Print</Button>}
                        content={() => attendReportRef.current}
                    />
                </Modal.Header>
                <Modal.Body className="" ref={attendReportRef}>

                    {attendReportData["Permanent"] ? <AttendanceReport
                        user={props.user}
                        assignee={assignee}
                        esta={"Permanent"}
                        employees={attendReportData["Permanent"]}
                        selectedDate={selectedDate}
                    /> : null}

                    {attendReportData["Co-Terminous"] ? <AttendanceReport
                        user={props.user}
                        assignee={assignee}
                        esta={"CO-TERMINOUS"}
                        employees={attendReportData["Co-Terminous"]}
                        selectedDate={selectedDate}
                    /> : null}

                    {attendReportData["CON"] ? <AttendanceReport
                        user={props.user}
                        assignee={assignee}
                        esta={"CONSULTANT"}
                        employees={attendReportData["CON"]}
                        selectedDate={selectedDate}
                    /> : null}

                    {attendReportData["CA"] ? <AttendanceReport
                        user={props.user}
                        assignee={assignee}
                        esta={"CASUAL"}
                        employees={attendReportData["CA"]}
                        selectedDate={selectedDate}
                    /> : null}

                    {attendReportData["JO"] ? <AttendanceReport
                        user={props.user}
                        assignee={assignee}
                        esta={"JOB ORDER"}
                        employees={attendReportData["JO"]}
                        selectedDate={selectedDate}
                    /> : null}

                </Modal.Body>
            </Modal>
            <Modal dialogClassName="edit-schedule-modal" show={showEdit} onHide={closeEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ScheduleEditModal
                        closeFetchEdit={closeFetchEdit}
                        sched={employeeSchedule.find(sched => sched.id === editSchedID)}
                        records={employeeRecords}
                    //schedID={editSchedID}
                    //keyID={editKeyID}
                    />
                </Modal.Body>
            </Modal>
            <Modal dialogClassName="create-sched-modal" show={showCreate} onHide={closeCreate}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ScheduleCreateModal
                        sDate={selectedDate}
                        closeCreate={closeCreate}
                        employeeList={employeeList}
                        divisionList={divisionList}
                        closeFetchCreate={closeFetchCreate}
                    />
                </Modal.Body>
            </Modal>
            <Modal dialogClassName="approved-list-modal" show={showApproved} onHide={closeApproved}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${new DateObject(selectedDate).format("MMMM")}`} DTR Status</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Row className="border-bottom m-0">
                        <Col className="text-center fs-4">
                            <Form.Label>Regular</Form.Label>
                        </Col>
                    </Row>
                    <Row className="border-bottom m-0">
                        <Col className="text-center" xs={8}>
                            <Form.Label>Name</Form.Label>
                        </Col>
                        <Col className="text-center">
                            <Form.Label>DTR Status</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {approvedListR.map((emp, key) => {
                                return <Row key={key} className="border-bottom m-0">
                                    <Col className="border-end" xs={8}>
                                        <Form.Label>{emp.employee}</Form.Label>
                                    </Col>
                                    <Col className="text-center">
                                        <Form.Label>
                                            {emp.emp_approved ? emp.chief_approved ? <DoneAllIcon className="text-success mt-0 me-2" /> : <CheckIcon style={{ fontSize: 20 }} className="text-primary me-1" /> : null}
                                        </Form.Label>
                                    </Col>
                                </Row>
                            })}
                        </Col>
                    </Row>

                    <Row className="border-bottom m-0">
                        <Col className="text-center fs-4">
                            <Form.Label>Non-Regular</Form.Label>
                        </Col>
                    </Row>
                    <Row className="border-bottom m-0">
                        <Col className="text-center" xs={8}>
                            <Form.Label>Name</Form.Label>
                        </Col>
                        <Col className="text-center">
                            <Form.Label>DTR A</Form.Label>
                        </Col>
                        <Col className="text-center">
                            <Form.Label>DTR B</Form.Label>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {approvedListNR.map((emp, key) => {
                                return <Row key={key} className="border-bottom m-0">
                                    <Col className="border-end" xs={8}>
                                        <Form.Label>{emp.employee}</Form.Label>
                                    </Col>
                                    <Col className="text-center border-end">
                                        <Form.Label>
                                            {emp.emp_approvedA ? emp.chief_approvedA ? <DoneAllIcon className="text-success mt-0 me-2" /> : <CheckIcon style={{ fontSize: 20 }} className="text-primary me-1" /> : null}
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            {emp.emp_approvedB ? emp.chief_approvedB ? <DoneAllIcon className="text-success mt-0 me-2" /> : <CheckIcon style={{ fontSize: 20 }} className="text-primary me-1" /> : null}
                                        </Form.Label></Col>
                                </Row>
                            })}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Row className="h-100 w-100 p-0 m-0">
                {!userIsMobile ? <Col className="h-100 p-0" xs={12} sm="2">
                    <EmployeeList
                        divisionList={divisionList}
                        employeeList={employeeList}
                        selectedEmployee={selectedEmployee}
                        selectEffect={setFetchEmployeeSchedule}
                        selectDivisionEffect={setSelectedDivision}
                        allowMultiple={false}
                        show={{ position: true, division: true, actions: true }}
                    />
                </Col> : null}
                <Col className="p-0 h-100" xs={12} sm={10}>
                    <Row className={`page-index-controls m-0 w-100`} style={userIsMobile ? {} : { height: '50px' }}>
                        <Col className="p-0" xs={12}>
                            <Row className="m-0">
                                <Col className="p-0" xs={12} md={"auto"}>
                                    <div style={{ paddingLeft: 3 }} className="d-flex flex-column align-items-left justify-content-center h-100">
                                        <div style={{ height: '30%', fontSize: '8pt', fontWeight: 'bold', paddingBottom: 4 }}>ATTENDANCE PERIOD:</div>
                                        <DatePicker className="m-auto"
                                            style={{ height: `${userIsMobile ? "100%" : "70%"}` }}
                                            format="MMMM YYYY"
                                            value={selectedDate}
                                            onChange={setSelectedDate}
                                            onlyMonthPicker
                                            inputClass="form-control text-center" />
                                    </div>
                                </Col>
                                <Col className="mt-1 mb-1 p-0" xs={12} md={true}>
                                    <Row className="m-0">
                                        <Col className={`${!userIsMobile ? "ms-auto " : "ms-1 me-1 "}mt-1 mb-1 ps-0 pe-1`} xs={"auto"} >
                                            <Button className="" variant="primary" size={userIsMobile ? "sm" : "md"} onClick={(e) => { openAttendReport() }}>Attendance Report</Button>
                                        </Col>
                                        <Col className="mt-1 mb-1 ps-1 pe-1" xs={"auto"}>
                                            <Button className="" variant="primary" size={userIsMobile ? "sm" : "md"} onClick={createAll}>Create Default Schedules</Button>
                                        </Col>
                                        <Col className="mt-1 mb-1 ps-1 pe-1" xs={"auto"}>
                                            <Button className="" variant="primary" size={userIsMobile ? "sm" : "md"} onClick={openCreate}>Create Custom Schedules</Button>
                                        </Col>
                                        <Col className="mt-1 mb-1 ps-1 pe-1" xs={"auto"}>
                                            <Button className="" variant="primary" size={userIsMobile ? "sm" : "md"} onClick={refreshDTR}>Refresh DTR</Button>
                                        </Col>
                                        <Col className="mt-1 mb-1 ps-1 pe-1" xs={"auto"}>
                                            <Button className="" variant="primary" size={userIsMobile ? "sm" : "md"} onClick={refreshOfficeDTR}>Refresh All DTR</Button>
                                        </Col>
                                        <Col className="mt-1 mb-1 ps-1" xs={"auto"}>
                                            <Button className="" variant="primary" size={userIsMobile ? "sm" : "md"} onClick={checkDTRApproved}>Check Approved</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {userIsMobile ? <Row className="h-50 p-1"><Col className="h-100" xs={12} sm="auto">
                        <EmployeeList
                            divisionList={divisionList}
                            employeeList={employeeList}
                            selectedEmployee={selectedEmployee}
                            selectEffect={setFetchEmployeeSchedule}
                            selectDivisionEffect={setSelectedDivision}
                            allowMultiple={false}
                            show={{ position: true, division: true, actions: true }}
                        />
                    </Col></Row> : null}
                    <Row className="m-0 p-1" style={userIsMobile ? {} : { height: 'calc(100% - 50px)' }}>
                        <Col className="h-100 p-0">
                            <div className="overflow-auto" style={userIsMobile ? {} : { height: 'calc(100% - 50px)' }}>
                                <div className="card overflow-auto">
                                    <div className="card-body" style={{ minWidth: "1100px" }}>
                                        <Row>
                                            <Col>
                                                <Row className="border border-2 border-bottom-2 border-dark text-center">
                                                    <Col className="border-end border-2 border-dark d-flex align-items-center" xs="2">
                                                        <Form.Label className="m-auto">Employee Schedule</Form.Label>
                                                    </Col>

                                                    <Col className="border-end border-2 border-dark" xs="4">
                                                        <Row className="border-bottom border-1 border-dark" >
                                                            <Col>
                                                                <Form.Label className="m-auto">Schedule</Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="border-bottom border-1 border-dark" >
                                                            <Col className="border-end border-1 border-dark" >
                                                                <Form.Label className="m-auto">AM</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark" >
                                                                <Form.Label className="m-auto">PM</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1">
                                                                <Form.Label className="m-auto">OT</Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IND</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>CUT</Form.Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col className="border-end border-2 border-dark" xs="4">
                                                        <Row className="border-bottom border-1 border-dark" >
                                                            <Col>
                                                                <Form.Label className="m-auto">Attendance</Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="border-bottom border-1 border-dark" >
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto">AM</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto">PM</Form.Label>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label className="m-auto">OT</Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col className="border-end border-2 border-dark d-flex align-items-center p-0" xs="1">
                                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>Undertime</Form.Label>
                                                    </Col>

                                                    <Col className="border-end border-2 border-dark d-flex align-items-center p-0" xs="1">
                                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>Overtime</Form.Label>
                                                    </Col>

                                                    {/* <Col className="d-flex align-items-center" xs="2">
                                                    <Form.Label className="m-auto">Remarks</Form.Label>
                                                </Col> */}

                                                </Row>

                                                {/* employeeSchedules */}
                                                <Row className="border-end border-start border-2 border-dark">
                                                    <Col>
                                                        {renderScheds()}
                                                    </Col>
                                                </Row>

                                                {employeeSchedule.length !== 0 ? ""
                                                    // <Row className="border border-2 border-dark">
                                                    //     <Col className="border-end border-2 border-dark" xs="2">
                                                    //         {`Total:  work days`}
                                                    //     </Col>
                                                    //     <Col className="border-end border-2 border-dark" xs="4">
                                                    //     </Col>
                                                    //     <Col className="border-end border-2 border-dark" xs="4">
                                                    //         {'Actual:  work days'}
                                                    //     </Col>
                                                    //     <Col className="border-end border-2 border-dark" xs="1">
                                                    //         {` min`}
                                                    //     </Col>
                                                    //     <Col className="border-end border-2 border-dark" xs="1">
                                                    //         {` min`}
                                                    //     </Col>
                                                    //     {/* <Col className="" xs="2">

                                                    //     </Col> */}
                                                    // </Row>
                                                    :
                                                    <Row className="border border-2 border-dark">
                                                        <Col className="text-center">
                                                            <Form.Label className="text-danger">
                                                                No Schedule Found
                                                            </Form.Label>
                                                        </Col>
                                                    </Row>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className={userIsMobile ? "text-center" : "d-flex flex-row justify-content-end align-items-center"} style={userIsMobile ? {} : { height: '50px' }}>
                                <div className={userIsMobile ? "" : "me-1"}>DTR Printing:</div>
                                <select style={userIsMobile ? {} : { width: '2in', height: '80%' }} className={userIsMobile ? "form-select" : "me-1 form-select"} aria-label="Select Target" name="print__select_target" value={printTarget}
                                    onChange={(e) => {
                                        setPrintTarget(e.target.value);
                                    }}>
                                    <option value={1}>Selected</option>
                                    <option value={2}>Regular</option>
                                    <option value={3}>Non-Regular</option>
                                </select>
                                <select style={userIsMobile ? {} : { width: '2in', height: '80%' }} className={userIsMobile ? "form-select mb-1" : "me-1 form-select"} aria-label="Select Month Range" name="print__select_range" value={printRange}
                                    onChange={(e) => {
                                        setPrintRange(e.target.value);
                                    }}>
                                    <option value={1}>Whole Month</option>
                                    <option value={2}>1st Half</option>
                                    <option value={3}>2nd Half</option>
                                </select>
                                <Button size="sm" style={{ height: '80%' }} variant="primary" onClick={(e) => {
                                    openPrint("dtr");
                                }}>Print DTR</Button>
                                <Button className="ms-1" size="sm" style={{ height: '80%' }} variant="primary" onClick={(e) => {
                                    openPrint("ot");
                                }}>Print OT</Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}